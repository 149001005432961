<template>
  <v-app>
    <v-col cols="11" class="mx-auto">
      <div class="text-justify">
        <div class="jumbotron p-5">
          <p class="h4 mb-2">Manage Claims Batch</p>
          <form @submit.prevent="getBatchId">
            <div class="row">
              <div class="col-md-7">
                <v-select
                  label="Please select a batch name to get started"
                  color="green darken-4"
                  :items="batchNames"
                  v-model="selected_batch"
                  name="batch"
                  outlined
                  append-icon="mdi-chevron-down"
                ></v-select>
              </div>
              <div class="col my-4">
                <v-btn color="green darken-4 white--text" medium type="submit"
                  >Open Batch</v-btn
                >
              </div>
            </div>
          </form>
        </div>
        <div class="flex-direction-column mt-5 pt-5">
          <p class="mb-5">
            <span class="font-weight-bold h5 mr-4">Add New Claim</span>
            <span class="ml-3">
              <v-btn
                :to="{ name: 'create-batch' }"
                class="green darken-4 white--text"
                medium
                >Create New Batch</v-btn
              >
            </span>
          </p>
          <span
            >Add a new claim record to the claims processing sub system</span
          >
          <br />
          <span>
            <strong>Please select a batch name to get started</strong> or create
            a new one
          </span>
        </div>
      </div>
      <v-row class="mb-12">
        <v-col cols="12" class="mt-12 mb-12">
          <table class="table table-striped">
            <thead class="text-uppercasepy-4">
              <tr class>
                <th>S/No</th>
                <th>Batch Name</th>
                <th>Batch ID</th>
                <th>Date Created</th>
                <th></th>
              </tr>
            </thead>
            <tbody v-for="(batch, index) in batches" :key="batch.id">
              <tr>
                <td>{{ index + 1 }}</td>
                <td>{{ batch.name }}</td>
                <td>{{ batch.batch_id }}</td>
                <td>{{ batch.date_created }}</td>
                <td>
                  <v-btn
                    :to="{
                      name: 'edit-batch',
                      params: {
                        batch_id: batch.id,
                      },
                    }"
                    medium
                    color="black darken-4 white--text"
                  >
                    <small class="font-weight-bold">Edit</small>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </table>
        </v-col>
      </v-row>
    </v-col>
  </v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "manage-batches",
  data() {
    return {
      loading: false,
      selected_batch: "",
      submitted_batch: "",
    };
  },
  methods: {
    getBatchId() {
      const self = this;
      this.batches.forEach(function (batch) {
        if (batch.name === self.selected_batch) {
          self.submitted_batch = batch.id;
          self.$router.push(
            `/claims-processing/view/batches/${self.submitted_batch}/claims`
          );
        }
      });
    },
  },
  computed: {
    ...mapGetters({ batches: "claimModule/batches" }),
    batchNames() {
      let batchList = this.batches.map((batch) => batch.name);
      return batchList;
    },
  },
};
</script>
<style scoped>
a:hover {
  color: white;
  text-decoration: none;
}
</style>
